<template>
    <!-- <div class="mx-auto p-2">

        <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div> -->
    <!-- <img class="h-auto w-full  mx-auto" src="../assets/bru-amb.png" alt="image description"> -->
    <!-- <div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel"> -->
      
  <div class="carousel-inner">
    <div class="carousel-item active">
      <!-- <a href="https://hub.brussels/fr/">

        <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
      </a>
      <a href="https://senegal.diplomatie.belgium.be/fr">

        <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
      </a> -->
      <div class="float-start" >
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../assets/logo-brusselbis.png" class="float-start" alt="...">
    <img src="../assets/logo-ambassade.png" class=" float-end" alt="...">
    </div>
  </div>
<!-- </div> -->
 
<!-- 
<div class="grid mb-6    md:mb-12 md:grid-cols-2 bg-white dark:bg-gray-800">
    <figure class="flex flex-col items-left justify-left p-6 text-left bg-white   md:rounded-t-none md:rounded-ss-lg  dark:bg-gray-800 dark:border-gray-700">
        <blockquote class="max-w-2xl  text-gray-500 lg:mb-8 dark:text-gray-400">
            
       <h6 class="text-sm font-semibold text-gradient-to-r from-teal-300 via-teal-300 to-teal-500 text-teal-400 dark:text-white "> <b>Délégation Economique et Commerciale Belge</b> <br>  Bureau Tri-Régional  <br> Ambassade de Belgique à Dakar <br> Juridiction : Sénégal - Gambie - Guinée - Guinée Bissau - Cabo Verde <br>
        <a href="mailto:Nairobi@hub.brussels?subject=Bonjour&body=La premiere ligne du message" class="font-medium text-gray-900 underline dark:text-gray-500 no-underline hover:no-underline">Dakar@hub.brussels</a></h6>
        </blockquote>
       
    </figure>
    <figure class="flex flex-col items-right justify-right p-8 text-right bg-white  md:rounded-se-lg ">
      
          <blockquote class="max-w-2xl  text-gray-500 lg:mb-8 dark:text-gray-400">
       <h6 class="text-sm font-semibold text-gradient-to-r from-teal-300 via-teal-300 to-teal-500 text-teal-400 dark:text-white "> <b>Belgian Economic and Commercial Delegation</b> <br>   Tri-Régional Office <br> Embassy of Belgium in Nairobi <br> Juridiction : Kenyan - Tanzania - Uganda <br>
        <a href="mailto:Nairobi@hub.brussels?subject=Bonjour&body=La premiere ligne du message" class="font-medium text-gray-900 underline dark:text-gray-500 no-underline hover:no-underline" > Nairobi@hub.brussels</a>
      </h6>
      
        </blockquote>
           
    </figure>
  
</div> -->


<nav class="bg-white border-gray-200 dark:bg-gray-900">
    <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
        <a href="/" class="flex items-center space-x-3 rtl:space-x-reverse text-white ">
            <!-- <img src="https://flowbite.com/docs/images/logo.svg" class="h-8" alt="Flowbite Logo" /> -->
            <span class="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">Flowbite</span>
        </a>
        <div class="flex items-center space-x-6 rtl:space-x-reverse">
            
            <a href="mailto:Dakar@hub.brussels?subject=Bonjour&body=La premiere ligne du message" class="inline-flex items-center justify-center px-3 py-2 me-3 text-xs font-medium text-gray-900 bg-white  rounded-lg focus:outline-none hover:bg-gray-100 no-underline hover:bg-gray-100 hover:underline focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope mx-2" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
               Dakar@hub.brussels</a>
            <a href="mailto:Nairobi@hub.brussels?subject=Goodmorning&body=First line of message" class="inline-flex items-center justify-center px-3 py-2 me-3 text-xs font-medium text-gray-900 bg-white  rounded-lg focus:outline-none no-underline hover:bg-gray-100 hover:underline focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope mx-2" viewBox="0 0 16 16">
  <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
</svg>
Nairobi@hub.brussels</a>
           
        </div>
    </div>
</nav>
<nav class="bg-gray-50 dark:bg-gray-700">
    <div class="max-w-screen-xl px-4 py-3 mx-auto">
        <div class="flex items-center">
            <ul class="flex flex-row font-medium mt-0 space-x-8 rtl:space-x-reverse text-sm">
                <li>
                    <a href="/" class="text-gray-900 dark:text-white no-underline hover:underline" aria-current="page">Accueil</a>
                </li>
                <li>
                    <a href="#organisateurs" class="text-gray-900 dark:text-white no-underline hover:underline">Partenaires</a>
                </li>
                <li>
                    <a href="#projet" class="text-gray-900 dark:text-white no-underline hover:underline">Projets</a>
                </li>
                <li>
                    <a href="#service" class="text-gray-900 dark:text-white no-underline hover:underline">Services</a>
                </li>
            </ul>
        </div>
    </div>
</nav>

<div id="carouselExampleSlidesOnly" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../assets/Brussels_wapenschild.png" class="d-block w-100 px-8 rounded-lg" alt="">
      <div class="carousel-caption d-none d-md-block">
        <h5>Délégation Economique et Commerciale Belge </h5>
        <p>Ambassade de Belgique à Dakar & à Nairobi  </p>
      </div>
    </div>
    <div class="carousel-item">
      <img src="../assets/Brussels_wapenschild.png" class="d-block w-100 px-8 rounded-lg" alt="">
      <div class="carousel-caption d-none d-md-block">
        <h5>Belgian Economic and Commercial Delegation </h5>
        <p>Embassy of Belgium in Dakar & in Nairobi </p>
      </div>
    </div>
   
  </div>
</div>


</template>
<style scoped>

</style>