<template>

    
    <section class="bg-white dark:bg-gray-900">
        <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
            <div class=" max-w-screen-sm text-center lg:mb-16 mb-8">
                <h2 id="organisateurs"
                    class="mb-2  px-4 card-title flex cursive uppercase font-medium  tracking-tight text-gray-900 dark:text-white">
                    Nos Partenaires

                </h2>

                <div class=" px-6 bg-white rounded-full h-2.5 dark:bg-white">
  <div class="bg-red-600 h-1.5 " style="width: 15%"></div>
</div>


            </div>
            <div class="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-4 md:gap-12 md:space-y-0">

                <div>
                    <a href="https://be.brussels/fr/propos-de-la-region">
                        <div
                            class="flex justify-center  p-6 items-center mb-4 cursor-pointer w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/brxcap.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://hub.brussels/fr/">
                        <div
                            class="flex justify-center  p-6 border-l items-center mb-4 cursor-pointer w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/logo-brussel.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://www.awex.be/">
                        <div
                            class="flex justify-center items-center p-6 border-l cursor-pointer mb-4 w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms mx-auto" src="../assets/logo-wallonie.png" alt="image description" />
                        </div>
                    </a>
                </div>
                <div>
                    <a href="https://welcome.flandersinvestmentandtrade.com/en">
                        <div
                            class="flex justify-center items-center border-l cursor-pointer p-6 mb-4 w-full h-full bg-primary-100 dark:bg-primary-900">
                            <img class="max-w-ms h-auto" src="../assets/logo-flanders.png" alt="image description" />
                        </div>
                    </a>
                </div>

            </div>
        </div>
        <div class="">
    <!-- <div class=" float-start " > 
        
        <div class="card" style="width: 18rem; border: none;">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6>
    <p class="card-text">
        hub.brussel vise à promouvoir l'internationalisation de l'économie bruxelloise en assistant les entreprises bruxelloises sur les marchés étrangers</p>
  
  </div>
</div>
        </div> -->
<!-- <div class=" float-end" >
    <div class="card" style="width: 18rem; border: none;">
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <h6 class="card-subtitle mb-2 text-body-secondary">Card subtitle</h6>
    <p class="card-text">
        Le service Economique et Commercial ne s'occupe pas de Visas</p>
  
  </div> -->
<!-- </div> -->
<!-- </div> -->
</div>
    </section>
    <section class="bg-gray-100 dark:bg-gray-900">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div class=" max-w-screen-sm text-center lg:mb-16 mb-8">
          <h2  id="service" class="mb-2  px-4 card-title flex cursive uppercase  font-medium tracking-tight text-gray-900 dark:text-white">NOS SERVICES</h2>
          <div class="px-6 bg-gray-100 rounded-full h-2.5 dark:bg-white">
  <div class="bg-red-600 h-1.5 " style="width: 15%"></div>
</div>
          <!-- <p class="font-light text-gray-500 sm:text-xl dark:text-gray-400">We use an agile approach to test assumptions and connect with the needs of your audience early and often.</p> -->
      </div> 
      <figure class="max-w-screen-md mx-auto text-center">
    <svg class="w-10 h-10 mx-auto mb-3 text-gray-400 dark:text-gray-600" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 18 14">
        <path d="M6 0H2a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3H2a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Zm10 0h-4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h4v1a3 3 0 0 1-3 3h-1a1 1 0 0 0 0 2h1a5.006 5.006 0 0 0 5-5V2a2 2 0 0 0-2-2Z"/>
    </svg>
    <blockquote>
       
        <p class="text-md italic font-medium text-gray-900 dark:text-white">" La Délégation Economique et Commerciale Belge à Dakar est un service extérieur des gouvernements de Bruxelles-Capitale, de Flandre et de Wallonie. Il représente les trois agences régionales du commerce extérieur (Hub.Brussels, FIT et AWEX) et a pour juridiction le Sénégal, la Gambie, la Guinée, le Cap-Vert et la Guinée-Bissau. Le service économique et commercial apporte un soutien au développement international de toutes les entreprises du territoire Belge."</p>
    <p class="text-md italic font-medium text-gray-900 dark:text-white"> La Délégation Economique et Commerciale ne s'occupe pas de Visas.</p>
    </blockquote>
    <figcaption class="flex items-center justify-center mt-6 space-x-3 rtl:space-x-reverse">
        <img class="w-6 h-6 rounded-full" src="../assets/1686131597283.jpeg" alt="profile picture">
        <div class="flex items-center divide-x-2 rtl:divide-x-reverse divide-gray-500 dark:divide-gray-700">
            <cite class="pe-3 font-medium text-gray-900 dark:text-white">Ivan Korsak Koulagenko</cite>
            <cite class="ps-3 text-sm text-gray-500 dark:text-gray-400">Trade Commissioner - Embassy of Belgium - West Africa - East Africa - Russia - China - Trade & Geopolitics</cite>
        </div>
    </figcaption>
</figure>
  </div>
</section>
    
</template> 
<style scoped>
.anim{
    animation: mymove 5s infinite;
  animation-delay: 2s
}
@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
</style>
