<template>

    
    
    <section class="bg-teal-100 dark:bg-teal-500">
  <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
    <div class=" max-w-screen-sm text-center lg:mb-16 mb-8">
                <h2 id=""
                    class="mb-2  px-4 card-title flex cursive uppercase font-medium  tracking-tight text-gray-900 dark:text-white">
                    prochains Evenements

                </h2>

                <div class=" px-6 bg-teal-100 rounded-full h-2.5 dark:bg-white">
  <div class="bg-red-600 h-1.5 " style="width: 15%"></div>
</div>


            </div>

<div class="   bg-white   md:p-2 dark:bg-gray-800 dark:border-gray-700">
  
   <div class="flow-root">
        <ul role="list" class="divide-y divide-gray-200 dark:divide-gray-700">
            <li class="  sm:py-4">
                <div class="flex items-center">
                    <div class="flex-shrink-0">
                        <p>November 2025</p>
                        <!-- <img class="w-8 h-8 rounded-full" src="/docs/images/people/profile-picture-1.jpg" alt="Neil image"> -->
                    </div>
                    
                    <div class="flex-1 min-w-0 ms-4">
                        <blockquote class=" pl-2 my-4 border-s-4 border-teal-300 bg-white dark:border-teal-500 dark:bg-teal-800">
    <p class="text-md italic font-medium leading-relaxed text-gray-900 dark:text-white">
        <p class="text-md font-medium text-gray-900 truncate dark:text-white">
                        Belgian Economic Mission
                        </p>
                        <p class="text-md text-gray-500 truncate dark:text-gray-400">
                           To Tanzania
                        </p></p>
</blockquote>
                       
                    </div>
                    <div class="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                        <button @click=" SowMissionTanzania()" type="button" class="text-white bg-teal-100 hover:bg-teal-300 focus:ring-4 focus:outline-none focus:ring-teal-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center me-2 dark:bg-teal-600 dark:hover:bg-teal-700 dark:focus:ring-teal-800">
<svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
<path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
</svg>
<span class="sr-only" >Icon description</span>
</button>
                    </div>
                  
                </div>
            </li>
         
        
           
        </ul>
   </div>
</div>

  </div>
</section>
    
</template> 

<script >
export default {
  name: 'MissionTanzania',
  components:{

  },
  data(){
    return{}

  },
methods:{
    SowMissionTanzania(){
    this.$router.push("/mission-to-tanzania")
  }, 
}}

</script>
<style scoped>
.anim{
    animation: mymove 5s infinite;
  animation-delay: 2s
}
@keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
}
</style>
